<template>
  <div>
    <b-card v-if="accountId">
      <b-button
        variant="danger"
        @click="onRemoveProfits"
      >
        Remove profits
      </b-button>
    </b-card>
    <gql-table
      ref="newsToTradeGqlTable"
      title="Restricted news trading"
      :fields="fields"
      :query="query"
      query-type="newsToTrades"
    >
      <template #cell(symbol)="data">
        {{ data.item.trade.symbol }}
      </template>

      <template #cell(type)="data">
        <div
          v-if="data.item.trade.type === 0"
          class="color-info"
        >
          Buy
        </div>
        <div
          v-else-if="data.item.trade.type === 1"
          class="color-warning"
        >
          Sell
        </div>
      </template>

      <template #cell(volume)="data">
        {{ formatNumber(data.item.trade.volume) }}
      </template>

      <template #cell(profit)="data">
        {{ formatNumber(data.item.trade.profit) }}
      </template>

      <template #cell(swap)="data">
        {{ formatNumber(data.item.trade.swap) }}
      </template>

      <template #cell(commission)="data">
        {{ formatNumber(data.item.trade.commission) }}
      </template>

      <template #cell(close_datetime)="data">
        {{ formatDateTime(data.item.trade.close_datetime) }}
      </template>

      <template #cell(open_datetime)="data">
        {{ formatDateTime(data.item.trade.open_datetime) }}
      </template>

      <template #cell(title)="data">
        {{ data.item.news.title }} | {{ data.item.news.country }}
      </template>

      <template #cell(date)="data">
        {{ formatDateTime(data.item.news.date) }}
      </template>

      <template #cell(impact)="data">
        <b-badge :variant="impactVariants[data.item.news.impact]">
          {{ data.item.news.impact }}
        </b-badge>
      </template>
      <template #cell(state)="data">
        <b-badge
          v-if="data.item.remove_profit && data.item.processed === null"
          variant="light-danger"
        >
          Added to withdraw
        </b-badge>
        <b-badge
          v-if="data.item.processed"
          variant="light-success"
        >
          Profit was removed
        </b-badge>
        <b-button
          v-if="!data.item.processed && !data.item.remove_profit"
          v-b-tooltip.hover.left="'Add to process'"
          variant="outline-danger"
          class="btn-icon m-50"
          @click="processTrade(data.item.trade.id)"
        >
          <feather-icon icon="CheckIcon" />
        </b-button>
        <b-button
          v-if="!data.item.processed && data.item.remove_profit"
          v-b-tooltip.hover.left="'Remove from process'"
          variant="outline-info"
          class="btn-icon m-50"
          @click="unProcessTrade(data.item.trade.id)"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </template>
    </gql-table>
  </div>
</template>
<script>
import {
  BBadge, BButton, BCard, VBTooltip,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'
import GqlTable from '@/components/ui/gqltables/GqlTable.vue'
import { IMPACT_VARIANTS } from '@/enums/news'
import { formatDateTime, formatNumber } from '@/plugins/formaters'
import TradeAPI from '@/api/trading-history'
import AccountAPI from '@/api/account'

const query = [
  'id',
  'remove_profit',
  'processed',
  {
    news: {
      fields: [
        'id',
        'title',
        'country',
        'date',
        'impact',
        'forecast',
        'previous',
        'restricted',
      ],
    },
  },
  {
    trade: {
      fields: [
        'id',
        'symbol',
        'type',
        'open_datetime',
        'close_datetime',
        'volume',
        'profit',
        'swap',
        'commission',
      ],
    },
  },
]
export default {
  components: {
    BButton, GqlTable, BBadge, BCard,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    accountId: String,
  },
  data() {
    return {
      impactVariants: IMPACT_VARIANTS,
      query,
    }
  },
  computed: {
    fields() {
      return [
        {
          name: 'symbol', label: 'Symbol',
        },
        { name: 'type', label: 'Type' },
        { name: 'open_datetime', label: 'Open date' },
        { name: 'close_datetime', label: 'Closed date' },
        { name: 'volume', label: 'Lots' },
        { name: 'swap', label: 'Swap' },
        { name: 'commission', label: 'Commission' },
        { name: 'profit', label: 'Profit' },
        { name: 'title', label: 'News title' },
        { name: 'date', label: 'Date' },
        { name: 'impact', label: 'Impact' },
        { name: 'state', label: 'State' },

        {
          name: 'account',
          label: 'Account',
          filterable: {
            type: 'eq', visible: false,
          },
          default: this.accountId,
          visible: false,
        },
      ]
    },
  },
  methods: {
    formatNumber,
    formatDateTime,
    processTrade(tradeId) {
      TradeAPI.postAddTradeToWithdrawal(tradeId)
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'The Trade wasn\'t added for processing.',
              icon: 'WarningIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.$refs.newsToTradeGqlTable.reloadAll(true)
        })
    },
    unProcessTrade(tradeId) {
      TradeAPI.postRemoveTradeFromWithdrawal(tradeId)
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'The Trade wasn\'t removed from processing.',
              icon: 'WarningIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.$refs.newsToTradeGqlTable.reloadAll(true)
        })
    },
    onRemoveProfits() {
      AccountAPI.getProfitToRemoveAmount(this.accountId).then(response => {
        const totalProfit = response.total_profit
        Swal.fire({
          title: 'Are you sure?',
          text: `${totalProfit} will be withdraw from account.`,
          icon: 'warning',
          showCancelButton: true,
          showDenyButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, withdraw!',
          denyButtonText: 'Yes, run full process.',

        }).then(result => {
          if (result.isConfirmed) {
            this.removeProfits(false)
          } else if (result.isDenied) {
            this.removeProfits(true)
          }
        })
      })
    },
    removeProfits(fullProcess) {
      AccountAPI.postWithdrawProfit(this.accountId, fullProcess).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Withdrawal was successful',
            icon: 'WarningIcon',
            variant: 'success',
          },
        })
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Withdrawal failed',
            icon: 'WarningIcon',
            variant: 'danger',
          },
        })
      }).finally(() => {
        this.$refs.newsToTradeGqlTable.reloadAll(true)
      })
    },
  },
}
</script>
