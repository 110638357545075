<template>
  <b-card>
    <app-collapse>
      <app-collapse-item
        v-if="balanceHistory"
        class="my-1"
        title="Balance history"
        :is-visible="false"
      >
        <b-table
          striped
          hover
          responsive
          small
          class="position-relative"
          :per-page="perPageHistory"
          :current-page="currentPageHistory"
          :fields="fieldsHistory"
          :items="balanceHistory"
        >
          <template #cell(CREATED_AT)="data">
            {{ utcDate(data.item.CREATED_AT) || utcDate(data.item.TIMESTAMP) }}
          </template>

          <template #cell(MODIFY_TIME_LOWEST)="data">
            {{ utcDate(data.item.MODIFY_TIME_LOWEST) }}
          </template>

          <template #cell(MODIFY_TIME_HIGHEST)="data">
            {{ utcDate(data.item.MODIFY_TIME_HIGHEST) }}
          </template>
        </b-table>
        <b-pagination
          v-model="currentPageHistory"
          :total-rows="totalRowsHistory"
          :per-page="perPageHistory"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </app-collapse-item>
      <app-collapse-item
        class="my-1"
        title="Balance history reports"
        :is-visible="false"
      >
        <div v-if="items.length > 0">
          <b-table
            striped
            hover
            responsive
            small
            class="position-relative"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="fields"
            :items="items"
          >

            <template #cell(balanceAt)="data">
              {{ utcDate(data.item.balanceAt) }}
            </template>

            <template #cell(balance)="data">
              {{ formatNumber(data.item.balance) }}
            </template>

            <template #cell(lowest)="data">
              {{ formatNumber(data.item.lowest) }}
            </template>

            <template #cell(lowest_datetime)="data">
              {{ utcDate(data.item.lowest_datetime) }}
            </template>

            <template #cell(createdAt)="data">
              {{ utcDate(data.item.createdAt) }}
            </template>

            <template #cell(failed)="data">
              <b-badge
                v-if="data.item.failed"
                variant="light-danger"
              >
                Failed
              </b-badge>
            </template>

            <template #cell(download)="data">
              <div
                v-if="data.item.failed"
              >
                <b-button
                  variant="outline-primary"
                  class="m-50"
                  @click="downloadReport(data.item.id, 'csv')"
                >
                  CSV
                </b-button>
                <b-button
                  variant="outline-primary"
                  class="m-50"
                  @click="downloadReport(data.item.id, 'txt')"
                >
                  TXT
                </b-button>
              </div>
            </template>
          </b-table>

          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
        <div v-else>
          No reports was captured yet
        </div>
      </app-collapse-item>
    </app-collapse>
  </b-card>
</template>
<script>
import {
  BBadge, BButton, BCard, BPagination, BTable, VBTooltip,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import copyToClipboard from '@/mixins/copyToClipboard'
import {
  formatCurrency, formatDate, formatDateTime, formatNumber, utcDate, utcToMtDate,
} from '@/plugins/formaters'
import AccountApi from '@/api/account'

export default {
  components: {
    AppCollapseItem,
    AppCollapse,
    BCard,
    BTable,
    BPagination,
    BBadge,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [copyToClipboard],
  props: {
    items: Array,
    balanceHistory: Array,
    accountId: [Number, String],
  },
  data() {
    return {
      ddLimit: 2.5,
      ddCap: 0,
      perPage: 10,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      fields: [
        { key: 'balanceAt', label: 'Balance captured at', sortable: true },
        { key: 'balance', label: 'Balance', sortable: true },
        { key: 'lowest', label: 'Lowest equity', sortable: true },
        { key: 'lowest_datetime', label: 'LE time', sortable: true },
        { key: 'createdAt', label: 'AVENTUS AT', sortable: true },
        { key: 'failed', label: 'AVENTUS Failed', sortable: true },
        { key: 'download', label: 'AVENTUS Report', sortable: false },
      ],
      equityEdit: null,

      perPageHistory: 10,
      pageOptionsHistory: [3, 5, 10],
      totalRowsHistory: 1,
      currentPageHistory: 1,
      fieldsHistory: [
        { key: 'CREATED_AT', label: 'Balance captured at', sortable: true },
        { key: 'BALANCE', label: 'Balance', sortable: true },
        { key: 'EQUITY_LOWEST', label: 'Lowest equity', sortable: true },
        { key: 'MODIFY_TIME_LOWEST', label: 'LE time', sortable: true },
        { key: 'EQUITY_HIGHEST', label: 'Highest equity', sortable: true },
        { key: 'MODIFY_TIME_HIGHEST', label: 'HE time', sortable: true },
      ],
    }
  },
  mounted() {
    this.totalRows = this.items.length
    this.totalRowsHistory = this.balanceHistory.length
  },
  methods: {
    formatNumber,
    formatDateTime,
    formatCurrency,
    formatDate,
    utcDate,
    utcToMtDate,
    downloadReport(reportId, type) {
      this.showLoader(true)
      AccountApi.getAventusRelativeReport(reportId, type, this.accountId)
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Request failed',
              icon: 'WarningIcon',
              text: err.statusText,
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.showLoader(false)
        })
    },
  },
}
</script>
