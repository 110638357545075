<template>
  <b-modal
    id="contractDataModal"
    title="Data"
    size="xl"
    :no-close-on-backdrop="true"
    hide-footer
    @close="$parent.onCloseContractDetail"
  >
    <contract-data-preview :account-id="$parent.accountToView" />
  </b-modal>
</template>
<script>
import { BModal } from 'bootstrap-vue'
import ContractDataPreview from '@/components/page/contract-veriff/ContractDataPreview.vue'

export default {
  components: { ContractDataPreview, BModal },
}
</script>
