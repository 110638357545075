<template>
  <b-modal
    id="accountCopyModal"
    title="Copy account"
    :no-close-on-backdrop="true"
    hide-footer
    @close="$parent.onCloseCopyModal(false)"
  >
    <account-copy-form
      :account="$parent.accountToCopy"
      @close="$parent.onCloseCopyModal(true)"
    />
  </b-modal>
</template>
<script>
import { BModal } from 'bootstrap-vue'
import AccountCopyForm from '@/components/forms/account/AccountCopyForm.vue'

export default {
  components: { AccountCopyForm, BModal },
}
</script>
