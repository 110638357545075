<template>
  <div v-if="!loaded">
    Loading...
  </div>
  <div v-else>
    <b-card>
      <div class="d-flex flex-wrap justify-content-between">
        <h1 class="font-medium-5 my-1">
          <b-link :to="{ name: userDetailLink, params: { id: user.id } }">
            {{ user.email }}
          </b-link>
          | {{ accountId }} - {{ account_data.account_type_name }} {{ account_data.account_subtype_name }}
        </h1>
        <action-list-button
          v-if="showActionButtons"
          :lists="optionActions"
          v-on="handlers"
        />
      </div>

      <app-collapse>
        <app-collapse-item
          class="my-1"
          title="Account info"
          :is-visible="!isMobile"
        >
          <b-row>
            <b-col
              v-if="account_data.risk_score !== null && account_data.risk_score !== 0"
              lg="2"
              md="2"
              class="mt-1"
            >
              <span>Risk score</span>
              <b-list-group class="mt-1">
                <b-list-group-item
                  v-for="(item, key) in account_data.risk_score_results"
                  :key="key"
                  class="d-flex justify-content-between"
                >
                  <span><strong>{{ key.replaceAll("_", " ").toUpperCase() }}:</strong></span>  <span>{{ item }}</span>
                </b-list-group-item>
              </b-list-group>
            </b-col>
            <b-col
              lg="2"
              md="2"
              class="mt-1"
            >
              <span
                v-if="account_data.comment"
                class="ml-50"
              >
                Account comment
              </span>
              <div
                v-if="account_data.comment"
                class="p-50 m-25"
              >
                {{ account_data.comment }}
              </div>
              <span
                v-if="account_data.user.comment"
                class="ml-50"
              >
                User comment
              </span>
              <div
                v-if="account_data.user.comment"
                class="p-50 m-25"
              >
                {{ account_data.user.comment }}
              </div>
            </b-col>
            <b-col
              lg="2"
              md="2"
              class="mt-1"
            >
              <span class="ml-50">Account tags</span>
              <div
                class="p-50 m-25"
              >
                <tags-list
                  :tags="account_data.tags"
                  :show-hidden-by-toggle="false"
                  :extras-tags="getAccountExtraTags(account_data)"
                />
              </div>
              <span class="ml-50">User tags</span>
              <div class="p-50 m-25">
                <tags-list
                  :tags="account_data.user.tags"
                  :show-hidden-by-toggle="false"
                  :extras-tags="getUserExtraTags(account_data.user)"
                  extras-variant="light-success"
                />
              </div>
            </b-col>
          </b-row>
        </app-collapse-item>
      </app-collapse>
    </b-card>
    <b-row class="match-height">
      <b-col
        xl="8"
        sm="12"
      >
        <b-row>
          <b-col lg="12">
            <statistics
              :data="statistics"
              :currency="currency"
            />
          </b-col>
          <b-col lg="12">
            <account-balance
              :samples="account_balance.length < 2"
              :data="account_balance"
              :account="account_data"
              :currency="currency"
            />
          </b-col>
          <b-col lg="12">
            <equity-daily-extremes
              :items="equity_extremes"
              :account-id="accountId"
              :currency="currency"
              :rule-drawdown="account_data.rule_drawdown_type"
              :deposit="account_data.deposit"
              :is-balance-based="account_data.rule_drawdown_type === 'initial-daily-simple' || account_data.rule_drawdown_type === 'relative'"
              :balance="account_data.balance"
              :withdrawal-datetime="account_data.withdrawal_datetime"
              :trading-history="trading_history"
            />
          </b-col>
          <b-col
            v-if="aventus_balance_history"
            lg="12"
          >
            <aventus-balance-history
              :items="aventus_balance_history"
              :balance-history="balance_equity_history"
              :account-id="accountId"
            />
          </b-col>
          <b-col lg="12">
            <goals-overview
              :data="goals"
              :currency="currency"
              :state="account_data.state"
              :account="account_data"
            />
            <withdrawal-overview
              v-if="account_data.account_subtype_name==='funded'"
              class="mb-2"
              :account="account_data"
              :currency="currency"
              :payout-availability="payout"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col
        xl="4"
        sm="12"
      >
        <b-row>
          <b-col
            xl="12"
            lg="6"
          >
            <account-data :data="account_data" />
          </b-col>
          <b-col
            xl="12"
            lg="6"
          >
            <account-equity
              v-if="equity_history"
              :data="equity_history"
              :samples="equity_history.length < 2"
              :currency="currency"
            />
          </b-col>
          <b-col
            v-if="balance_equity_history"
            xl="12"
            lg="6"
          >
            <balance-history
              :data="balance_equity_history"
              :samples="balance_equity_history.length === 0"
            />
          </b-col>
          <b-col
            xl="12"
            lg="6"
          >
            <account-loss-analysis
              :currency="currency"
              :data="goals"
              :account="account_data"
              :equity="equity"
              class="h-100 mb-1"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col lg="12">
        <trading-acc-history
          :samples="trading_history.length === 0"
          :data="trading_history"
          :currency="currency"
          :account="account_data"
        />
      </b-col>
      <b-col lg="12">
        <news-to-trade-table-gql
          :account-id="account_data.id"
          class="mb-1"
        />
      </b-col>
      <b-col lg="12">
        <account-withdrawal-gql-table
          :account-id="account_data.id"
          class="mb-1"
        />
      </b-col>
      <b-col lg="12">
        <b-row>
          <b-col
            xl="4"
            lg="6"
            xs="12"
          >
            <long-short-analysis
              :samples="trading_history.length === 0"
              :data="long_short_analysis"
              :currency="currency"
            />
          </b-col>
          <b-col
            xl="4"
            lg="6"
            xs="12"
          >
            <instrument-analysis
              :samples="trading_history.length === 0"
              :data="instrument_analysis"
              :currency="currency"
            />
          </b-col>
          <b-col
            xl="4"
            lg="6"
            xs="12"
          >
            <trading-volume
              :samples="trading_volume.length === 0"
              :data="trading_volume"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <account-edit-modal />
    <deposit-withdraw-modal />
    <account-reset-modal />
    <contract-data-modal />
    <account-copy-modal />
    <download-funded-certificate-modal />
  </div>
</template>

<script>
import {
  BRow, BCol, BLink, BCard, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import { dispatch, get } from 'vuex-pathify'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AccountData from './widgets/AccountData.vue'
import AccountBalance from './widgets/AccountBalance.vue'
import AccountLossAnalysis from './widgets/AccountLossAnalysis.vue'
import TradingAccHistory from './widgets/TradingAccHistory.vue'
import GoalsOverview from './widgets/GoalsOverview.vue'
import Statistics from './widgets/Statistics.vue'
import TradingVolume from './widgets/TradingVolume.vue'
import AccountEquity from './widgets/AccountEquity.vue'
import InstrumentAnalysis from './widgets/InstrumentAnalysis.vue'
import LongShortAnalysis from './widgets/LongShortAnalysis.vue'
import { ACCOUNTS_SHORT } from '@/enums/accounts'
import EquityDailyExtremes from '@/views/account/detail/widgets/EquityDailyExtremes.vue'
import TagsList from '@/components/page/TagsList.vue'
import { getAccountExtraTags } from '@/plugins/accountUtils'
import { getUserExtraTags } from '@/plugins/userUtils'
import AventusBalanceHistory from '@/views/account/detail/widgets/AventusBalanceHistory.vue'
import BalanceHistory from '@/views/account/detail/widgets/BalanceHistory.vue'
import WithdrawalOverview from '@/views/account/detail/widgets/WithdrawalOverview.vue'
import NewsToTradeTableGql from '@/components/entity/account-gql/NewsToTradeTableGql.vue'
import AccountWithdrawalGqlTable from '@/components/entity/withdrawalGql/AccountWithdrawalGqlTable.vue'
import accountActions from '@/mixins/accountActions'
import userActions from '@/mixins/userActions'
import ActionListButton from '@/components/ui/ActionListButton.vue'

export default {
  components: {
    ActionListButton,
    AccountWithdrawalGqlTable,
    NewsToTradeTableGql,
    WithdrawalOverview,
    BalanceHistory,
    AventusBalanceHistory,
    AppCollapse,
    AppCollapseItem,
    EquityDailyExtremes,
    AccountData,
    BRow,
    BCol,
    BLink,
    BCard,
    BListGroup,
    BListGroupItem,
    Statistics,
    AccountBalance,
    AccountLossAnalysis,
    TradingAccHistory,
    GoalsOverview,
    TradingVolume,
    AccountEquity,
    InstrumentAnalysis,
    LongShortAnalysis,
    TagsList,
  },
  mixins: [accountActions, userActions],
  props: {
    showActionButtons: {
      type: Boolean,
      default: true,
    },
    userDetailLink: {
      type: String,
      default: 'user-detail',
    },
  },
  data() {
    return {
      loaded: false,
      accountNames: ACCOUNTS_SHORT,
      accountActionsFilterOut: ['account-detail'],
      userActionsFilterIn: ['ip-checker', 'loginAs'],
    }
  },
  computed: {
    handlers() {
      return { ...this.accountHandlers, ...this.userHandlers }
    },
    optionActions() {
      return [
        { data: this.account_data.user, actions: this.userActionsList },
        { data: this.account_data, actions: this.accountActionsList },
      ]
    },
    accountId() {
      return this.$route.params.id
    },
    ...get('accountsList@row', {
      account_data: 'account_data',
      trading_history: 'trading_history',
      trading_volume: 'trading_volume',
      long_short_analysis: 'long_short_analysis',
      goals: 'goals',
      equity_history: 'equity_history',
      account_balance: 'account_balance',
      user: 'user',
      statistics: 'statistics',
      instrument_analysis: 'instrument_analysis',
      equity_extremes: 'equity_extremes',
      aventus_balance_history: 'aventus_balance_history',
      balance_equity_history: 'balance_equity_history',
      equity: 'equity',
      payout: 'payout',
    }),
    ...get('app', ['isMobile']),
    currency() {
      return this.account_data.currency.label
    },
  },
  beforeMount() {
    this.load()
  },
  methods: {
    getAccountExtraTags,
    getUserExtraTags,
    onClickIpCheck() {
      const route = this.$router.resolve({ name: 'tools-ip-check', params: { id: this.account_data.user.id } })
      window.open(route.href)
    },
    reload() {
      this.load()
    },
    load() {
      dispatch('accountsList/get', this.accountId)
        .then(() => {
          this.loaded = true
        }).catch(err => {
          if (err.status === 404) {
            this.$router.push({ name: 'error-404' })
          }
        })
    },
  },
}
</script>
