<template>
  <b-modal
    id="downloadCertificateModal"
    title="Download funded certificate"
    :no-close-on-backdrop="true"
    hide-footer
  >
    <certificate-download-form
      :loading="$parent.downloadLoading"
      @download="$parent.onDownloadFunded"
    />
  </b-modal>
</template>
<script>
import { BModal } from 'bootstrap-vue'
import CertificateDownloadForm from '@/components/forms/certificate/CertificateDownloadForm.vue'

export default {
  components: { BModal, CertificateDownloadForm },
}
</script>
