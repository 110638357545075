<template>
  <b-dropdown
    class="my-1"
    :text="title"
    variant="outline-primary"
    right
  >
    <span
      v-for="(items,key) in lists"
      :key="key"
    >
      <b-dropdown-item
        v-for="item in items.actions"
        @click="onActionClick(item.emit, items.data)"
      >
        <feather-icon
          v-if="item.icon"
          :icon="item.icon"
          size="12"
          class="mr-25"
        />
        {{ item.text }}
      </b-dropdown-item>
    </span>
  </b-dropdown>
</template>
<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue'

export default {
  components: { BDropdownItem, BDropdown },
  props: {
    title: {
      type: String,
      default: 'Options',
    },
    lists: Array,
  },
  methods: {
    onActionClick(emit, data) {
      this.$emit(emit, data)
    },
  },
}
</script>
