<template>

  <b-overlay
    :show="this.$parent.refreshing"
    variant="overlay-main"
    spinner-variant="primary"
  >
    <b-card
      no-body
      class="mb-0 h-100"
    >
      <b-card-header class="align-items-baseline px-1 pt-1 pb-25">
        <b-card-title>
          <b-button
            id="popover-account-loss-analysis"
            class="w-100 text-center p-0"
            variant="link"
          >
            <h4 class="vh-center font-small-4 mb-0">Account loss analysis
              <feather-icon
                class="ml-50 text-info"
                size="18"
                icon="InfoIcon"
              />
            </h4>
          </b-button>
          <b-popover
            target="popover-account-loss-analysis"
            variant="primary"
            triggers="hover"
            placement="top"
          >
            <template #title>
              <div class="w-100 vh-center">
                <span class="text-center mx-auto">Account loss analysis</span>
              </div>
            </template>
            <p class="text-center">
              Be aware of the Daily Loss Limit and Max Loss Limit. This tool will help you stay on top of your Daily
              Loss and Max Overall Loss objectives.
            </p>
          </b-popover>
        </b-card-title>
        <div
          class="d-flex v-center my-50"
        >
          <b-form-radio-group
            v-model="type"
            button-variant="outline-primary"
            :options="typeOptions"
            buttons
            size="sm"
            class="customize-radio"
          />
          <b-popover
            target="popover-account-loss-analysis-countdown"
            variant="primary"
            triggers="hover"
            placement="top"
          >
            <template #title>
              <div class="text-center">
                <span class="text-center mx-auto">Next daily loss reset</span>
              </div>
            </template>
            <p class="text-center">
              When countdown ends your daily loss will be reset, but initial deposit loss will stay as he was.
            </p>
          </b-popover>
        </div>
      </b-card-header>
      <b-card-body class="px-1 py-50">
        <b-card
          :class="`bg-${skin==='dark'?'dark-gradient':'light-body'}`"
          class="mb-50"
          body-class="px-75 py-50"
        >
          <div
            v-for="item in objectives"
            :key="`ala-${item.field}`"
            class="loss-item"
          >
            <div
              v-if="data[item.field]"
            >
              <span class="text-header">{{ item.name }}</span>
              <b-row class="mt-25 align-items-center no-gutters">
                <b-col
                  cols="12"
                  class="mb-25"
                >
                  <b-progress
                    v-b-tooltip.hover.bottom="`${data[item.field].from ? formatCurrency(data[item.field].from - currentEquity(data[item.field]), currency) : 'Calculating...'}`"
                    :value="getValue(data[item.field])"
                    max="100"
                    :variant="getColor(data[item.field])"
                  />
                </b-col>
                <b-col
                  v-if="data[item.field].from"
                  cols="6"
                  class="font-small-1"
                >
                  {{ item.entry }}: {{ formatCurrency(data[item.field].from, currency) }}
                </b-col>
                <b-col
                  v-if="data[item.field].from"
                  cols="6"
                  class="text-right font-small-2 font-weight-bold text-info"
                >
                  Loss level: {{ formatCurrency(data[item.field].to, currency) }}
                </b-col>
                <b-col
                  v-if="data[item.field].from"
                  cols="6"
                  class="font-small-1"
                >
                  Updated: {{ formatDateTime(getUpdateTime(data[item.field])) }}
                </b-col>
                <b-col
                  v-if="!data[item.field].from"
                  cols="12"
                  class="font-small-1"
                >
                  Calculating. Please wait...
                </b-col>
              </b-row>
            </div>
          </div>
          <div
            v-if="data['max_daily_drawdown'] && showTimer"
            class="font-small-2 font-weight-bold vh-center text-header"
          >
            Next daily loss reset in:
            <b-card
              body-class="px-50 py-25 text-primary custom-fixed-width-2 "
              class="mb-0 ml-25"
            >
              <!--              00:00:00-->
              {{ dailyReset }}
            </b-card>
          </div>
        </b-card>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCol,
  BOverlay,
  BPopover,
  BProgress,
  BRow,
  BFormRadioGroup,
  VBPopover,
  VBTooltip,
} from 'bootstrap-vue'
import { get } from 'vuex-pathify'
import { formatCurrency, formatDateTime } from '@/plugins/formaters'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BOverlay,
    BRow,
    BCol,
    BButton,
    BPopover,
    BProgress,
    BFormRadioGroup,
  },
  directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },
  props: {
    currency: String,
    data: Object,
    account: Object,
    equity: Object,
    showTimer: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const getObjectivesText = (obj, field) => {
      if (obj === 'initial') {
        if (field === 'name') {
          if (['e8trialone', 'e8one'].includes(this.account.account_type_name)) {
            return 'Trailing Drawdown'
          } if (['e8futures1', 'e8futures2'].includes(this.account.account_type_name)) {
            return 'EOD Total Drawdown'
          }
          if (['e8futures3'].includes(this.account.account_type_name)) {
            return 'Equity Trailing Drawdown'
          }
          return 'Initial balance limit level'
        }
        if (field === 'entry') {
          if (['e8trialone', 'e8one'].includes(this.account.account_type_name)) {
            return 'High Water-Mark level'
          } if (['e8futures1', 'e8futures2'].includes(this.account.account_type_name)) {
            return 'EOD Total Drawdown'
          } if (['e8futures3'].includes(this.account.account_type_name)) {
            return 'Equity Trailing Drawdown'
          } return 'Initial balance'
        }
      } else {
        if (field === 'name') {
          if (['e8pro', 'e8futures1'].includes(this.account.account_type_name)) {
            return 'Daily pause limit level'
          } return 'Daily loss limit level'
        }
        if (field === 'entry') {
          return 'Entry equity'
        }
      }
    }
    return {
      dailyReset: this.timeToReset(),
      timer: null,
      type: 'maximal',
      objectives: [
        {
          name: getObjectivesText('initial', 'name'),
          field: 'max_total_drawdown',
          entry: getObjectivesText('initial', 'entry'),
          reset: false,
        },
        {
          name: getObjectivesText('daily', 'name'),
          field: 'max_daily_drawdown',
          entry: getObjectivesText('daily', 'entry'),
          reset: true,
        },
      ],
    }
  },
  computed: {
    ...get('appConfig@layout', ['skin']),
    typeOptions() {
      return [
        { text: 'Max recorded', value: 'maximal' },
        { text: 'Current', value: 'current', disabled: !this.equity },
      ]
    },
  },
  watch: {
    equity(n) {
      if (n === null) {
        this.type = 'maximal'
      }
    },
  },
  created() {
    this.timer = setInterval(() => {
      this.dailyReset = this.timeToReset()
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    formatDateTime,
    formatCurrency,
    currentEquity(data) {
      // eslint-disable-next-line no-nested-ternary
      return this.type === 'current' ? Math.max((data.from ? (data.from - this.equity.value) : 0), 0) : data.current
    },
    getValue(data) {
      return data.from ? (this.currentEquity(data) / (data.from - data.to)) * 100 : 0
    },
    getColor(data) {
      const val = this.getValue(data)
      if (val < 40) {
        return 'primary'
      }
      if (val < 70) {
        return 'warning'
      }
      return 'danger'
    },
    getUpdateTime(data) {
      return this.type === 'current' ? new Date(this.equity.timestamp * 1000) : data.datetime
    },
    timeToReset() {
      const d = new Date()
      let diff = new Date(d).setUTCHours(22, 0, 0, 0) - d
      if (diff < 0) {
        const dd = new Date()
        dd.setDate(dd.getDate() + 1)
        diff = new Date(dd).setUTCHours(22, 0, 0, 0) - d
      }
      const h = Math.floor(diff / 3.6e6)
      const m = Math.floor((diff % 3.6e6) / 6.0e4)
      const s = Math.floor((diff % 6.0e4) / 1.0e3)

      return `${h}:${`${m}`.padStart(2, '0')}:${`${s}`.padStart(2, '0')}`
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-fixed-width-1 {
  width: 85px;
  text-align: center;
  @media screen and (max-width: 370px) {
    display: none;
  }
}

.custom-fixed-width-2 {
  width: 75px;
  text-align: center;
}

.loss-item:not(:last-child) {
  margin-bottom: 1rem;
}

.customize-radio::v-deep {
  .btn{
    font-size: 0.7rem !important;
  }
}
</style>
