<template>
  <b-modal
    id="resetModal"
    title="Reset Account"
    :no-close-on-backdrop="true"
    hide-footer
    size="xl"
  >
    <account-reset-form
      :account-id="$parent.accountToView"
      @submit="$parent.onAccountResetSubmit"
    />
  </b-modal>
</template>
<script>
import { BModal } from 'bootstrap-vue'
import AccountResetForm from '@/components/forms/account/AccountResetForm.vue'

export default {
  components: { AccountResetForm, BModal },
}
</script>
