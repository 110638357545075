<template>
  <withdrawal-gql-table
    :columns="columns"
    :show-action-bar="false"
    title="Payouts"
  />
</template>
<script>
import WithdrawalGqlTable from '@/components/entity/withdrawalGql/WithdrawalGqlTable.vue'

export default {
  components: {
    WithdrawalGqlTable,
  },
  props: {
    accountId: String,
  },
  computed: {
    columns() {
      return [
        {
          name: 'user',
          label: 'User',
        },
        {
          name: 'account',
          label: 'Account',
          filterable: {
            type: 'eq', visible: false,
          },
          default: this.accountId,
        },
        {
          name: 'Deposit',
          label: 'Deposit',
          sortable: false,
        },
        {
          name: 'current_balance',
          label: 'Current balance on account',
          sortable: true,
        },
        {
          name: 'captured_balance',
          label: 'Captured balance',
          sortable: true,
        },
        {
          name: 'amount',
          label: 'Profit share $',
          sortable: true,
        },
        {
          name: 'profit_share',
          label: 'Profit share %',
          sortable: true,
        },
        {
          name: 'withdrawal_datetime',
          label: 'Withdrawal time',
          sortable: true,
        },
        {
          name: 'state',
          label: 'State',
          sortable: false,
          filterable: {
            type: 'multienum',
            enum: ['requested', 'investigation', 'investigation - contacted', 'investigation - declined', 'approved by support', 'completed'],
          },
        },
        {
          name: 'platform',
          label: 'Platform',
          sortable: false,
        },
        {
          name: 'confirmed_at',
          label: 'Confirmed at',
          sortable: true,
        },
        {
          name: 'createdAt',
          label: 'Created at',
          sortable: true,
        },
        {
          name: 'stateAction',
          label: 'State action',
          sortable: false,
        },
        {
          name: 'accountDeposit',
          label: 'Deposit',
          filterable: 'range',
          default: [1, null],
          visible: false,
        },
      ]
    },
  },
}
</script>
