import axiosInstance from '@/plugins/axios'

export default {
  getConsentImage(consentId, imgName) {
    const url = `admin/consents/${consentId}/image/${imgName}`

    return axiosInstance.get(url, {
      responseType: 'arraybuffer',
    })
      .then(response => Buffer.from(response.data, 'binary').toString('base64'))
  },
  postRestoreDecision(consentId, declineReason = null) {
    const url = `admin/consents/${consentId}/restore`

    return axiosInstance.post(url, { reason: declineReason })
      .then(response => response.data)
  },

  postSaveAndRestoreDecision(consentId, consent) {
    const url = `admin/consents/${consentId}/restore-save`

    return axiosInstance.post(url, consent)
      .then(response => response.data)
  },

  patchConsent(consentId, data) {
    const url = `admin/consents/${consentId}`

    return axiosInstance.patch(url, data)
      .then(response => response.data)
  },

  removeConsent(id) {
    const url = `admin/consents/${id}`

    return axiosInstance.delete(url)
      .then(response => response.data)
  },
}
