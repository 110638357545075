<template>
  <b-modal
    id="accountModal"
    title="Edit Account"
    :no-close-on-backdrop="true"
    hide-footer
  >
    <account-form
      module-name="accountsList"
      :account="$parent.accountEdit"
      :automatic-reload="false"
      @submit="$parent.onEditAccountSubmit()"
    />
  </b-modal>
</template>
<script>
import { BModal } from 'bootstrap-vue'
import AccountForm from '@/components/forms/account/AccountForm.vue'

export default {
  components: { AccountForm, BModal },
}
</script>
