import Swal from 'sweetalert2'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { dispatch } from 'vuex-pathify'
import store from '@/store'
import UserAPI from '@/api/user'
import UserModal from '@/components/modals/user/UserModal.vue'

const actions = [
  {
    text: 'Account details',
    icon: 'SearchIcon',
    emit: 'user-detail',
  },
  {
    text: 'Edit',
    icon: 'EditIcon',
    emit: 'user-edit',
  },
  {
    text: 'Verify',
    icon: 'CheckCircleIcon',
    emit: 'verify',
  },
  {
    text: 'Reset verification',
    icon: 'UserXIcon',
    emit: 'resetVerify',
  },
  {
    text: 'Reset TFA',
    icon: 'KeyIcon',
    emit: 'resetTFA',
  },
  {
    text: 'Login as user',
    icon: 'KeyIcon',
    emit: 'loginAs',
  },
  {
    text: 'IP checker',
    icon: 'XOctagonIcon',
    emit: 'ip-checker',
  },
  {
    text: 'Remove user',
    icon: 'Trash2Icon',
    emit: 'remove-user',
  },
]

export default {
  components: {
    UserModal,
  },
  data() {
    const vm = this
    return {
      availableUserActions: actions,
      userEdit: null,
      userActionsFilterOut: [],
      userActionsFilterIn: [],
      userHandlers: {
        'user-detail': value => vm.onDetail(value),
        'user-edit': value => vm.onEdit(value),
        verify: value => vm.onVerify(value),
        resetVerify: value => vm.onResetVerify(value),
        resetTFA: value => vm.onResetTFA(value),
        loginAs: value => vm.onLoginAs(value),
        'ip-checker': value => vm.onClickIpCheck(value),
        'remove-user': value => vm.onRemoveUser(value),
      },
    }
  },
  computed: {
    userActionsList() {
      let actionList = this.availableUserActions

      if (this.userActionsFilterOut.length > 0) actionList = actionList.filter(action => !this.userActionsFilterOut.includes(action.emit))

      if (this.userActionsFilterIn.length > 0) actionList = actionList.filter(action => this.userActionsFilterIn.includes(action.emit))

      return actionList
    },
  },
  methods: {
    onEdit(row) {
      dispatch('usersList/get', row.id).then(data => {
        this.userEdit = data.user_data
        this.$bvModal.show('userModal')
      })
    },
    onEditSubmit() {
      this.$bvModal.hide('userModal')
      this.reload()
    },
    onCreateUser() {
      this.userEdit = null
      this.$bvModal.show('userModal')
    },
    onDetail(item) {
      this.$router.push({ name: 'user-detail', params: { id: item.id } })
    },
    onVerify(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'User will be verified',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, verify him!',
      }).then(result => {
        if (result.isConfirmed) {
          store.dispatch('usersList/update', { id: item.id, data: { kyc_at: new Date().toUTCString() }, autoReload: false }).finally(() => {
            this.reload()
          })
        }
      })
    },
    onResetVerify(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Verification data will be removed and user will have to complete verification again',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, reset verification!',
      }).then(result => {
        if (result.isConfirmed) {
          store.dispatch('usersList/update', { id: item.id, data: { kyc_at: null, verification_data: null }, autoReload: false }).finally(() => {
            this.reload()
          })
        }
      })
    },
    onResetTFA(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: `TFA of ${item.email} will be reset`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, reset!',
      }).then(result => {
        if (result.isConfirmed) {
          this.userDeleteLoading = true
          UserAPI.resetTFA(item.id)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'TFA of user is disable now',
                  icon: 'KeyIcon',
                  variant: 'success',
                },
              })
            })
            .catch(error => {
              if (error.status === 400) {
                Swal.fire({
                  title: 'TFA reset failed',
                  text: error.data.detail,
                  icon: 'warning',
                  showCancelButton: false,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Ok',
                })
              }
            })
            .finally(() => {
              this.reload()
            })
        }
      })
    },
    onRemoveUser(item) {
      Swal.fire({
        title: 'Remove user',
        text: `Are you sure you want to remove this user: ${item.email}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, remove!',
      }).then(result => {
        if (result.isConfirmed) {
          UserAPI.deleteUser(item.id)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Remove successful',
                  icon: 'TrashIcon',
                  text: 'User was successfully removed',
                  variant: 'success',
                },
              })
              this.reload()
            })
        }
      })
    },
    onPromote(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'User will be notified about this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, promote him!',
      }).then(result => {
        if (result.isConfirmed) {
          UserAPI.promoteUserToPartner(item.id)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'User was promote to affiliate',
                  icon: 'SendIcon',
                  text: 'User was successfully promote to affiliate.',
                  variant: 'success',
                },
              })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Promotion failed',
                  icon: 'XCircleIcon',
                  text: error.status === 400 ? 'User was probably already promoted' : 'Server error! Try later',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    onLoginAs(item) {
      UserAPI.postLoginAs(item.id)
        .then(response => {
          Swal.fire({
            title: 'Login as user',
            html: `<p>Here is user login link: (link is valid for 2 minutes)</p><a target="_blank" href="${response}">${response}</a>`,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok',
          })
        })
    },
    onResetMtrPassword(item) {
      Swal.fire({
        title: 'Reset MatchTrader password',
        text: 'Are you sure? The password will be changed for active MTR accounts.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, change password!',
      }).then(result => {
        if (result.isConfirmed) {
          UserAPI.resetMtrPwd(item.id)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Password reset was successful',
                  icon: 'UserIcon',
                  variant: 'success',
                },
              })
            }).catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Password reset failed.',
                  icon: 'UserIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    onClickIpCheck(item) {
      const route = this.$router.resolve({ name: 'tools-ip-check', params: { id: item.id } })
      window.open(route.href)
    },
  },
}
