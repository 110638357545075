<template>
  <div class="goals-overview">
    <b-overlay
      :show="this.$parent.refreshing || refreshing"
      :variant="skin"
      spinner-variant="primary"
    >
      <b-card>
        <b-card-header>
          <b-card-title>Goals overview</b-card-title>
          <span
            class="v-center text-primary font-weight-bold loading-page cursor-pointer"
            :class="this.$parent.refreshing?'loading':''"
            @click="onRefreshClick"
          >
            <feather-icon
              icon="RefreshCwIcon"
              size="20"
            />
          </span>
        </b-card-header>
        <b-card-body>
          <b-row class="no-gutters m-05 mt-0 mb-2">
            <b-col
              v-if="state === 'failed'"
              sm="12"
            >
              <div
                class="m-05 py-2 px-1 border-radius-7"
                :class="`bg-${skin==='dark'?'darker':'light'}`"
              >
                <p class="font-weight-bold text-danger font-small-4 mb-0 text-center">
                  You have failed to adhere to one of the Trading Objectives below and your account is not eligible to continue trading.</p>
              </div>
            </b-col>
            <b-col
              v-if="state === 'completed'"
              sm="12"
            >

              <div class="m-05 e8-border-gradient">
                <div
                  class="py-2 px-1 border-radius-12"
                  :class="`bg-${skin==='dark'?'darker':'light'}`"
                >
                  <p class="text-primary font-medium-5 text-center font-weight-bolder">
                    Congratulations
                  </p>
                  <p
                    class="font-small-4 text-center font-weight-bold mb-0"
                    :class="`text-${skin==='light'?'dark':'white'}`"
                  >
                    You have successfully passed this phase of your evaluation! You will receive an e-mail with confirmation that your new account has ben set up to continue to the next phase.
                  </p>
                </div>
              </div>
            </b-col>
            <b-col
              v-if="account.pending_close"
              sm="12"
            >
              <div class="m-05 e8-border-gradient">
                <div
                  class="py-2 px-1 border-radius-12"
                  :class="`bg-${skin==='dark'?'darker':'light'}`"
                >
                  <p
                    class="font-small-4 text-center font-weight-bold mb-0"
                    :class="`text-${skin==='light'?'dark':'white'}`"
                  >
                    You are currently passing all the trading objectives but you still have running trades. Please close all your trades to finish account check.
                  </p>
                </div>
              </div>
            </b-col>

            <b-col
              v-for="(objective, key, index) in objectives"
              :key="index"
              lg="6"
            >
              <div
                v-if="data[key] !== undefined"
                class="m-05 py-2 px-1 border-radius-7"
                :class="`bg-${skin==='dark'?'darker':'light'}`"
              >
                <div
                  class="mr-auto font-weight-bold v-center justify-content-between"
                >
                  <b-button
                    :id="`popover-goals-overview-${objective.id}`"
                    variant="unstyled"
                    class="button-transparent pl-0 pr-0"
                  >
                    <span
                      class="border-bottom"
                      :class="`text-${skin==='light'?'dark':'white'}`"
                    >
                      {{ typeof objective.name === 'function' ? objective.name() : objective.name }}
                    </span>
                    <feather-icon
                      size="18"
                      icon="InfoIcon"
                      class="ml-05"
                      :class="`text-${skin==='light'?'dark':'white'}`"
                    />
                  </b-button>
                  <b-popover
                    :target="`popover-goals-overview-${objective.id}`"
                    variant="warning"
                    triggers="hover"
                    placement="top"
                  >
                    <template #title>
                      <span>{{ typeof objective.name === 'function' ? objective.name() : objective.name }}</span>
                    </template>
                    <span>{{ objective.text }}</span>
                  </b-popover>
                  <div class="d-none d-xl-block">
                    <div v-if="objective.status === 'passing'">
                      <b-badge
                        v-if="data[key].current>=data[key].target_value"
                        variant="danger"
                      >
                        Failed
                      </b-badge>
                      <b-badge
                        v-else
                        variant="success"
                      >
                        {{ state !== 'active' ? 'Passed' : 'Passing' }}
                      </b-badge>
                    </div>
                    <div v-if="objective.status === 'pending'">
                      <b-badge
                        v-if="state === 'active' && data[key].current<data[key].target_value"
                        variant="warning"
                      >
                        Pending
                      </b-badge>
                      <b-badge
                        v-else-if="state==='failed' && data[key].current<data[key].target_value"
                        variant="danger"
                      >
                        Failed
                      </b-badge>
                      <b-badge
                        v-else
                        variant="success"
                      >
                        {{ state !== 'active' ? 'Passed' : 'Passing' }}
                      </b-badge>
                    </div>
                  </div>
                </div>

                <div class="v-center justify-content-between m-05 mx-0">
                  <span>{{ objective.status === 'pending' ? 'Minimum' : 'Maximum' }}:</span>
                  <div>
                    {{
                      objective.type === 'currency' ? formatCurrency(data[key].target_value, currency) : `${data[key].target_value}`
                    }}
                    <span
                      v-if="objective.type !== 'currency'"
                    >
                      {{ data[key].target_value === 1 ? 'Day' : 'Days' }}
                    </span>
                  </div>
                </div>
                <div
                  class="v-center ml-auto mr-auto text-center justify-content-between"
                >
                  <span
                    v-if="objective.result"
                  >{{ objective.result }}:</span>
                  <span v-else>Current result:</span>
                  <div>
                    <div v-if="objective.status === 'passing'">
                      <b-badge
                        v-if="data[key].current>=data[key].target_value"
                        variant="light-danger"
                        class="font-medium-1"
                      >
                        <div>
                          {{
                            objective.type === 'currency' ? formatCurrency(data[key].current, currency) : `${data[key].current}`
                          }}
                          <span
                            v-if="objective.type !== 'currency'"
                          >
                            {{ data[key].target_value === 1 ? 'Day' : 'Days' }}
                          </span>
                        </div>
                      </b-badge>
                      <b-badge
                        v-else
                        variant="light-secondary"
                        class="font-medium-1"
                      >
                        <div>
                          {{
                            objective.type === 'currency' ? formatCurrency(data[key].current, currency) : `${data[key].current}`
                          }}
                          <span
                            v-if="objective.type !== 'currency'"
                          >
                            {{ data[key].target_value === 1 ? 'Day' : 'Days' }}
                          </span>
                        </div>
                      </b-badge>
                    </div>
                    <div v-else-if="objective.status === 'pending'">
                      <b-badge
                        v-if="state === 'active' && data[key].current<data[key].target_value"
                        variant="light-warning"
                        class="font-medium-1"
                      >
                        <div>
                          {{
                            objective.type === 'currency' ? formatCurrency(data[key].current, currency) : `${data[key].current}`
                          }}
                          <span
                            v-if="objective.type !== 'currency'"
                          >
                            {{ data[key].target_value === 1 ? 'Day' : 'Days' }}
                          </span>
                        </div>
                      </b-badge>
                      <b-badge
                        v-else-if="state==='failed' && data[key].current<data[key].target_value"
                        variant="light-danger"
                        class="font-medium-1"
                      >
                        <div>
                          {{
                            objective.type === 'currency' ? formatCurrency(data[key].current, currency) : `${data[key].current}`
                          }}
                          <span
                            v-if="objective.type !== 'currency'"
                          >
                            {{ data[key].target_value === 1 ? 'Day' : 'Days' }}
                          </span>
                        </div>
                      </b-badge>
                      <b-badge
                        v-else
                        variant="light-success"
                        class="font-medium-1"
                      >
                        <div>
                          {{
                            objective.type === 'currency' ? formatCurrency(data[key].current, currency) : `${data[key].current}`
                          }}
                          <span
                            v-if="objective.type !== 'currency'"
                          >
                            {{ data[key].target_value === 1 ? 'Day' : 'Days' }}
                          </span>
                        </div>
                      </b-badge>
                    </div>
                    <div v-else>
                      <b-badge
                        v-if="state === 'active' && data[key].current<data[key].target_value"
                        variant="light-warning"
                        class="font-medium-1"
                      >
                        <div>
                          {{
                            objective.type === 'currency' ? formatCurrency(data[key].current, currency) : `${data[key].current}`
                          }}
                          <span
                            v-if="objective.type !== 'currency'"
                          >
                            {{ data[key].target_value === 1 ? 'Day' : 'Days' }}
                          </span>
                        </div>
                      </b-badge>
                      <b-badge
                        v-else-if="state==='failed' && data[key].current<data[key].target_value"
                        variant="light-danger"
                        class="font-medium-1"
                      >
                        <div>
                          {{
                            objective.type === 'currency' ? formatCurrency(data[key].current, currency) : `${data[key].current}`
                          }}
                          <span
                            v-if="objective.type !== 'currency'"
                          >
                            {{ data[key].target_value === 1 ? 'Day' : 'Days' }}
                          </span>
                        </div>
                      </b-badge>
                      <b-badge
                        v-else
                        variant="light-success"
                        class="font-medium-1"
                      >
                        <div>
                          {{
                            objective.type === 'currency' ? formatCurrency(data[key].current, currency) : `${data[key].current}`
                          }}
                          <span
                            v-if="objective.type !== 'currency'"
                          >
                            {{ data[key].target_value === 1 ? 'Day' : 'Days' }}
                          </span>
                        </div>
                      </b-badge>
                    </div>
                  </div>
                </div>
                <div
                  class="v-center text-center justify-content-between d-flex d-xl-none m-05 mb-0 mx-0"
                >
                  <span>Status:</span>
                  <div>
                    <div v-if="objective.status === 'passing'">
                      <b-badge
                        v-if="data[key].current>=data[key].target_value"
                        variant="danger"
                      >
                        Failed
                      </b-badge>
                      <b-badge
                        v-else
                        variant="success"
                      >
                        {{ state !== 'active' ? 'Passed' : 'Passing' }}
                      </b-badge>
                    </div>
                    <div v-if="objective.status === 'pending'">
                      <b-badge
                        v-if="state === 'active' && data[key].current<data[key].target_value"
                        variant="warning"
                      >
                        Pending
                      </b-badge>
                      <b-badge
                        v-else-if="state==='failed' && data[key].current<data[key].target_value"
                        variant="danger"
                      >
                        Failed
                      </b-badge>
                      <b-badge
                        v-else
                        variant="success"
                      >
                        {{ state !== 'active' ? 'Passed' : 'Passing' }}
                      </b-badge>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BBadge, BButton, BCol, BRow, BPopover, VBPopover, BCard, BCardHeader, BCardTitle, BCardBody, BOverlay,
} from 'bootstrap-vue'
import { get } from 'vuex-pathify'
import { $themeColors } from '@themeConfig'
import { formatCurrency } from '@/plugins/formaters'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    BButton,
    BPopover,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BOverlay,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    data: Object,
    currency: String,
    state: String,
    account: Object,
    onRefresh: Function,
  },
  data() {
    return {
      refreshing: false,
    }
  },
  computed: {
    ...get('appConfig@layout', ['skin']),
    objectives() {
      const objectives = {}
      if (this.data.min_trading_days) {
        objectives.min_trading_days = {
          id: 1,
          icon: 'CalendarIcon',
          name: 'Minimum trading days',
          type: 'number',
          status: 'pending',
          text: 'This is the number of active trading days measured in UTC timezone.',
          result: 'Current result',
          color: this.getColor(1, this.calculateTarget(this.data.min_trading_days.current, this.data.min_trading_days.target_value)),
          chartColor: this.getColor(1, this.calculateTarget(this.data.min_trading_days.current, this.data.min_trading_days.target_value), true),
          badgeText: this.getBadgeText('pending', this.data.min_trading_days),
        }
      }
      if (this.data.min_profit) {
        objectives.min_profit = {
          id: 2,
          icon: 'TargetIcon',
          name: 'Profit target',
          type: 'currency',
          status: 'pending',
          text: 'This value represents your profit in closed balance.',
          result: ['e8futures1', 'e8futures2', 'e8futures3'].includes(this.account.account_type_name) ? 'Current floating profit' : 'Current result',
          color: this.getColor(2, this.calculateTarget(this.data.min_profit.current, this.data.min_profit.target_value)),
          chartColor: this.getColor(2, this.calculateTarget(this.data.min_profit.current, this.data.min_profit.target_value), true),
          badgeText: this.getBadgeText('pending', this.data.min_profit),
        }
      }
      if (this.data.max_daily_drawdown) {
        objectives.max_daily_drawdown = {
          id: 3,
          icon: 'TrendingDownIcon',
          name: ['e8pro', 'e8futures1'].includes(this.account.account_type_name) ? 'Daily pause' : 'Daily loss',
          type: 'currency',
          status: 'passing',
          text: this.account.account_type_name === 'e8pro' ? 'If you breach the daily pause level, your account will be stopped until the end of the day and you can continue after. Failing daily stop level doesn\'t breach your account (This is not instant and it can take up to 5 minutes until all trades are fully closed).' : 'This is the lowest equity drop recorded on one particular day. It contains your floating loss + closed loss and will always represent the largest loss on your account.',
          result: ['e8futures1', 'e8futures2', 'e8futures3'].includes(this.account.account_type_name) ? 'Current floating loss' : 'Max. loss recorded',
          color: this.getColor(3, this.calculateTarget(this.data.max_daily_drawdown.current, this.data.max_daily_drawdown.target_value)),
          chartColor: this.getColor(3, this.calculateTarget(this.data.max_daily_drawdown.current, this.data.max_daily_drawdown.target_value), true),
          badgeText: this.getBadgeText('passing', this.data.max_daily_drawdown),
        }
      }
      if (this.data.max_total_drawdown) {
        objectives.max_total_drawdown = {
          id: 4,
          icon: 'TrendingDownIcon',
          name: () => {
            if (['e8trialone', 'e8one'].includes(this.account.account_type_name)) return 'Trailing drawdown loss'
            if (['e8futures1', 'e8futures2'].includes(this.account.account_type_name)) return 'EOD Total Drawdown'
            if (['e8futures3'].includes(this.account.account_type_name)) return 'Equity Trailing Drawdown'
            return 'Initial balance loss'
          },
          type: 'currency',
          status: 'passing',
          text: ['e8trialone', 'e8one'].includes(this.account.account_type_name) ? 'https://help.e8markets.com/en/articles/9122280-relative-drawdown-calculation-and-explanation-for-e8-one-account' : 'This is the lowest recorded equity of your account from the time you started trading.',
          result: ['e8futures1', 'e8futures2', 'e8futures3'].includes(this.account.account_type_name) ? 'Current floating loss' : 'Max. loss recorded',
          color: this.getColor(4, this.calculateTarget(this.data.max_total_drawdown.current, this.data.max_total_drawdown.target_value)),
          chartColor: this.getColor(4, this.calculateTarget(this.data.max_total_drawdown.current, this.data.max_total_drawdown.target_value), true),
          badgeText: this.getBadgeText('passing', this.data.max_total_drawdown),
        }
      }
      if (this.data.best_day && this.account.account_subtype_name !== 'funded') {
        objectives.best_day = {
          id: 5,
          icon: 'PercentIcon',
          name: 'Best day rule',
          type: 'currency',
          text: 'Your best day profit can not be higher than 50 % of your total performance results',
          result: 'Current best day profit',
          maxText: 'Best day rule',
          chart: false,
        }
      }

      return objectives
    },
  },
  methods: {
    formatCurrency,
    onRefreshClick() {
      this.refreshing = true
      this.onRefresh(['goals']).then(() => {
        this.refreshing = false
      })
    },
    calculateTarget(current, target) {
      return Number(current) / (Number(target) / 100)
    },
    getBadgeText(status, data) {
      if (status === 'passing') {
        if (data.current >= data.target_value) return 'Failed'
        return this.state !== 'active' ? 'Passed' : 'Passing'
      }
      if (status === 'pending') {
        if (this.state === 'active' && data.current < data.target_value) return 'Pending'
        if (this.state === 'failed' && data.current < data.target_value) return 'Failed'
        return this.state !== 'active' ? 'Passed' : 'Passing'
      }
    },
    getColor(id, value, chart = false) {
      if (id === 1) {
        if (value < 40) return chart ? $themeColors.danger : 'danger'
        if (value < 70) return chart ? $themeColors.warning : 'warning'
        return chart ? $themeColors.primary : 'primary'
      }
      if (id === 2) {
        if (value < 0) return chart ? $themeColors.danger : 'danger'
        if (value > 0) return chart ? $themeColors.primary : 'primary'
      }

      if (value < 40) return chart ? $themeColors.primary : 'primary'
      if (value < 70) return chart ? $themeColors.warning : 'warning'
      return chart ? $themeColors.danger : 'danger'
    },
  },
}
</script>

<style lang="scss" scoped>
.collapse-row {
  padding: 10px 0;
  border-bottom: 1px solid grey;

  &:last-child {
    border: none
  }
}
</style>

<style lang="scss">
.goals-overview {
  .card-body {
    padding: 0;
  }
}
</style>
