<template>
  <b-modal
    id="depositWithdrawModal"
    title="Deposit or withdraw to account"
    :no-close-on-backdrop="true"
    hide-footer
    @close="$parent.onCloseDWmodal"
  >
    <deposit-withdraw-form
      :account="$parent.accountEdit"
      @close="$parent.onCloseDWmodal"
    />
  </b-modal>
</template>
<script>
import { BModal } from 'bootstrap-vue'
import DepositWithdrawForm from '@/components/forms/account/DepositWithdrawForm.vue'

export default {
  components: { DepositWithdrawForm, BModal },
}
</script>
