<template>
  <b-overlay
    :show="isBusy"
    rounded="sm"
  >
    <h3 v-if="account">
      Account ID: {{ account.account_id }}
    </h3>
    <b-form-group
      label="Amount"
    >
      <b-form-input
        v-model.number="form.amount"
        type="number"
        min="0"
      />
    </b-form-group>
    <hr>
    <div class="d-flex justify-content-between align-items-center">
      <b-button
        variant="outline-success"
        :disabled="btnDisable"
        @click="onDepositWithdraw('deposit')"
      >
        Deposit
      </b-button>
      <b-button
        variant="outline-danger"
        :disabled="btnDisable"
        @click="onDepositWithdraw('withdraw')"
      >
        Withdraw
      </b-button>
    </div>
  </b-overlay>
</template>
<script>
import {
  BButton, BOverlay, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'
import AccountAPI from '@/api/account'

export default {
  components: {
    BOverlay, BButton, BFormGroup, BFormInput,
  },
  props: {
    account: Object,
  },
  data() {
    return {
      isBusy: false,
      form: {
        amount: 0,
        action: null,
      },
    }
  },
  computed: {
    btnDisable() {
      return this.form.amount <= 0 || !['MTR', 'CTRADER'].includes(this.account.platform.id)
    },
  },
  methods: {
    onDepositWithdraw(action) {
      this.form.action = action

      Swal.fire({
        title: 'Are you sure?',
        text: `You will ${this.form.action.toUpperCase()} ${this.form.amount} ${this.form.action === 'deposit' ? 'to' : 'from'} account ${this.account.account_id}.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then(result => {
        if (result.isConfirmed) {
          this.isBusy = true
          AccountAPI.depositWithdrawFromAccount(this.account.id, this.form).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Balance was adjusted successfully',
                icon: 'WarningIcon',
                variant: 'success',
              },
            })
            this.$emit('close')
          }).catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Deposit or withdrawal failed.',
                icon: 'WarningIcon',
                variant: 'danger',
              },
            })
          }).finally(() => {
            this.isBusy = false
          })
        }
      })
    },
  },
}
</script>
