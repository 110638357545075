import Swal from 'sweetalert2'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { dispatch } from 'vuex-pathify'
import AccountAPI from '@/api/account'
import AccountEditModal from '@/components/modals/account/AccountEditModal.vue'
import AccountResetModal from '@/components/modals/account/AccountResetModal.vue'
import AccountCopyModal from '@/components/modals/account/AccountCopyModal.vue'
import ContractDataModal from '@/components/modals/account/ContractDataModal.vue'
import DepositWithdrawModal from '@/components/modals/account/DepositWithdrawModal.vue'
import DownloadFundedCertificateModal from '@/components/modals/account/DownloadFundedCertificateModal.vue'

const actions = [
  {
    text: 'Account details',
    icon: 'SearchIcon',
    emit: 'account-detail',
  },
  {
    text: 'Edit account',
    icon: 'EditIcon',
    emit: 'edit-account',
  },
  {
    text: 'Risk score',
    icon: 'XOctagonIcon',
    emit: 'risk-score',
  },
  {
    text: 'Reset password',
    icon: 'RepeatIcon',
    emit: 'resetPwd',
  },
  {
    text: 'Duplicate account to phase 1',
    icon: 'CopyIcon',
    emit: 'duplicate',
  },
  {
    text: 'Copy account',
    icon: 'CopyIcon',
    emit: 'copy',
  },
  {
    text: 'Account reset',
    icon: 'RepeatIcon',
    emit: 'accountReset',
  },
  {
    text: 'View contract detail',
    icon: 'EyeIcon',
    emit: 'view-contract-detail',
  },
  {
    text: 'Disable account',
    icon: 'DeleteIcon',
    emit: 'disable',
  },
  {
    text: 'Enable account',
    icon: 'CheckCircleIcon',
    emit: 'enable',
  },
  {
    text: 'Force complete account',
    icon: 'CheckCircleIcon',
    emit: 'forceComplete',
  },
  {
    text: 'AVENTUS',
    icon: 'RepeatIcon',
    emit: 'aventus',
  },
  {
    text: 'AVENTUS history',
    icon: 'RepeatIcon',
    emit: 'aventus-history',
  },
  {
    text: 'Remove certificate',
    icon: 'TrashIcon',
    emit: 'removeCertificate',
  },
  {
    text: 'Download funded certificate',
    icon: 'DownloadIcon',
    emit: 'downloadFundedCertificate',
  },
  {
    text: 'Deposit / Withdraw',
    icon: 'RepeatIcon',
    emit: 'depositWithdraw',
  },
  {
    text: 'Remove account',
    icon: 'Trash2Icon',
    emit: 'remove-account',
  },
]

export default {
  components: {
    AccountEditModal,
    AccountResetModal,
    AccountCopyModal,
    ContractDataModal,
    DepositWithdrawModal,
    DownloadFundedCertificateModal,
  },
  data() {
    const vm = this
    return {
      availableAccountActions: actions,
      accountEdit: null,
      accountToView: null,
      accountToCopy: null,
      accountDownloadCertificate: null,
      downloadLoading: false,
      accountActionsFilterOut: [],
      accountActionsFilterIn: [],
      accountHandlers: {
        'account-detail': value => vm.onDetail(value),
        'edit-account': value => vm.onEditAccount(value),
        resetPwd: value => vm.onResetPassword(value),
        duplicate: value => vm.onDuplicate(value),
        copy: value => vm.onCopy(value),
        accountReset: value => vm.onAccountReset(value),
        'view-contract-detail': value => vm.onViewContractDetail(value),
        disable: value => vm.onDisable(value),
        enable: value => vm.onEnable(value),
        forceComplete: value => vm.onForceComplete(value),
        aventus: value => vm.onAventus(value),
        'aventus-history': value => vm.onAventusHistory(value),
        'remove-account': value => vm.onRemoveAccount(value),
        'risk-score': value => vm.onClickRiskScore(value),
        removeCertificate: value => vm.onRemoveCertificate(value),
        downloadFundedCertificate: value => vm.onDownloadFundedCertificate(value),
        depositWithdraw: value => vm.onDepositWithdraw(value),
      },
    }
  },
  computed: {
    accountActionsList() {
      let actionList = this.availableAccountActions

      if (this.accountActionsFilterOut.length > 0) actionList = actionList.filter(action => !this.accountActionsFilterOut.includes(action.emit))

      if (this.accountActionsFilterIn.length > 0) actionList = actionList.filter(action => this.accountActionsFilterIn.includes(action.emit))

      return actionList
    },
  },
  methods: {
    onEditAccount(item) {
      dispatch('accountsList/get', item.account_id).then(data => {
        this.accountEdit = data.account_data
        this.$bvModal.show('accountModal')
      })
    },
    onEditAccountSubmit() {
      this.$bvModal.hide('accountModal')
      this.reload()
    },
    onClickRiskScore(item) {
      const route = this.$router.resolve({ name: 'tools-risk-score', params: { id: item.account_id } })
      window.open(route.href)
    },
    onDepositWithdraw(item) {
      this.accountEdit = item
      this.$bvModal.show('depositWithdrawModal')
    },
    onCloseDWmodal() {
      this.$bvModal.hide('depositWithdrawModal')
      this.accountEdit = null
    },
    onViewContractDetail(item) {
      this.accountToView = item.account_id
      this.$bvModal.show('contractDataModal')
    },
    onCloseContractDetail() {
      this.accountToView = null
    },
    onCopy(item) {
      if (item.account_subtype_name === 'funded') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Action failed.',
            icon: 'WarningIcon',
            text: 'You can not copy FUNDED account!',
            variant: 'danger',
          },
        })
        return
      }

      this.accountToCopy = item
      this.$bvModal.show('accountCopyModal')
    },
    onCloseCopyModal(reloadTable = false) {
      this.$bvModal.hide('accountCopyModal')
      this.accountToCopy = null
      if (reloadTable) this.reload()
    },
    onAccountReset(item) {
      this.accountToView = item.id
      this.$bvModal.show('resetModal')
    },
    onAccountResetSubmit() {
      this.$bvModal.hide('resetModal')
    },
    onAventusHistory(item) {
      const route = this.$router.resolve({ name: 'aventus', params: { id: item.account_id } })
      window.open(route.href)
    },
    onAventus(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: `Account ${item.account_id} will be checked by AVENTUS`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, run analysis',
      }).then(result => {
        if (result.isConfirmed) {
          AccountAPI.requestAventus(item.id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Account was add to the front successfully',
                icon: 'WarningIcon',
                text: 'Account is waiting for test now!',
                variant: 'success',
              },
            })
            this.reload()
          })
        }
      })
    },
    onDetail(item) {
      this.$router.push({ name: 'account-detail', params: { id: item.account_id } })
    },
    onForceComplete(item) {
      if (item.state === 'completed') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Unable to proceed',
            icon: 'WarningIcon',
            text: 'Account is completed already!',
            variant: 'danger',
          },
        })
        return
      }
      Swal.fire({
        title: 'Are you sure?',
        text: `Account ${item.account_id} will be marked as complete`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, force complete',
      }).then(result => {
        if (result.isConfirmed) {
          AccountAPI.forceCompleteAccount(item.id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Account was completed successfully',
                icon: 'WarningIcon',
                text: 'Account is complete now!',
                variant: 'success',
              },
            })
            this.reload()
          })
        }
      })
    },
    onDisable(item) {
      if (item.state === 'failed' && !item.is_active) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Unable to proceed',
            icon: 'WarningIcon',
            text: 'Account is disabled already!',
            variant: 'danger',
          },
        })
        return
      }
      Swal.fire({
        title: 'Are you sure?',
        text: `Account ${item.account_id} will be disabled and set as failed`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, disable',
      }).then(result => {
        if (result.isConfirmed) {
          AccountAPI.disableAccount(item.id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Account was disable successfully',
                icon: 'WarningIcon',
                text: 'Account is disabled now!',
                variant: 'success',
              },
            })
            this.reload()
          })
        }
      })
    },
    onDuplicate(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: `Account ${item.account_id} will be duplicated to phase 1`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, duplicate',
      }).then(result => {
        if (result.isConfirmed) {
          AccountAPI.duplicateAccount(item.id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Account was duplicated successfully',
                icon: 'WarningIcon',
                text: 'Account is duplicated now!',
                variant: 'success',
              },
            })
            this.reload()
          }).catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Action failed.',
                icon: 'WarningIcon',
                text: 'Account duplication failed!',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
    onRemoveCertificate(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Funded certificate will be remove',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, remove',
      }).then(result => {
        if (result.isConfirmed) {
          AccountAPI.removeFundedCertificate(item.id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Certificate was removed',
                icon: 'TrashIcon',
                text: '',
                variant: 'success',
              },
            })
          })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'TrashIcon',
                  text: error.data.detail,
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    onDownloadFundedCertificate(item) {
      this.accountDownloadCertificate = item
      this.$bvModal.show('downloadCertificateModal')
    },
    onDownloadFunded(form) {
      this.downloadLoading = true

      AccountAPI.getCertificate(this.accountDownloadCertificate.account_id, form.color, form.type, form.size, form.forceGenerate)
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'TrashIcon',
              text: error.status === 400 ? 'Certificate is not generated yet' : error.statusText,
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.accountDownloadCertificate = null
          this.downloadLoading = false
          this.$bvModal.hide('downloadCertificateModal')
        })
    },
    onEnable(item) {
      if (item.state === 'active' && item.is_active) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Unable to proceed',
            icon: 'WarningIcon',
            text: 'Account is active already!',
            variant: 'danger',
          },
        })
        return
      }
      Swal.fire({
        title: 'Are you sure?',
        text: `Account ${item.account_id} will be enabled and set as active`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, enable',
      }).then(result => {
        if (result.isConfirmed) {
          AccountAPI.enableAccount(item.id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Account was enabled successfully',
                icon: 'WarningIcon',
                text: 'Account is activated now!',
                variant: 'success',
              },
            })
            this.reload()
          })
        }
      })
    },
    onResetPassword(item) {
      if (item.state !== 'active') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Unable to proceed',
            icon: 'WarningIcon',
            text: 'Account has to be active!',
            variant: 'danger',
          },
        })
        return
      }
      Swal.fire({
        title: 'Are you sure?',
        text: `Account ${item.account_id} will receive new password`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, recreate',
      }).then(result => {
        if (result.isConfirmed) {
          AccountAPI.resetAccountPwd(item.id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Password was changed successfully',
                icon: 'WarningIcon',
                variant: 'success',
              },
            })
          }).catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Password was change failed!',
                icon: 'WarningIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
    onRemoveAccount(item) {
      Swal.fire({
        title: 'Remove this account',
        text: `Are you sure you want to remove this account ${item.account_id}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, remove!',
      }).then(result => {
        if (result.isConfirmed) {
          AccountAPI.deleteAccount(item.id)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Remove successful',
                  icon: 'TrashIcon',
                  text: 'Account was successfully removed',
                  variant: 'success',
                },
              })
              this.reload()
            })
        }
      })
    },
  },
}
