<template>
  <b-modal
    id="userModal"
    title="User edit"
    :no-close-on-backdrop="true"
    hide-footer
  >
    <user-form
      :user="$parent.userEdit"
      @submit="$parent.onEditSubmit()"
    />
  </b-modal>
</template>
<script>
import UserForm from '@/components/forms/user/UserForm.vue'

export default {
  components: { UserForm },
}
</script>
